<template>
  <div class="template-add">
    <CRow>
      <CCol md="7">
        <CCard>
          <CCardBody>
            <ValidationObserver ref="templateForm" v-slot="{ handleSubmit }">
              <form
                @submit.prevent="handleSubmit(onSubmit)"
                name="templateForm"
              >
                <!-- <draggable
                  v-model="templateData"
                  group="people"
                  @start="drag = true"
                  @end="drag = false"
                  @change="handleChangeSort"
                > -->
                <CRow v-for="(temp, index) in templateData" :key="index">
                  <CCol col="12">
                    <div class="d-flex justify-content-between">
                      <div class="mx-2">
                        <!-- <span class="handle"></span> -->
                        <span class="d-flex align-items-baseline">
                          <span class="font-weight-bold text-muted text-nowrap"
                            >Sec {{ index + 1 }}.</span
                          ></span
                        >
                        <div class="text-center">
                          <i
                            class="fa-solid fa-trash-can text-danger"
                            v-if="templateData.length > 1"
                            @click="openDeleteModal(index)"
                          ></i>
                        </div>
                      </div>
                      <div class="w-100">
                        <CRow>
                          <CCol md="12">
                            <CRow>
                              <label
                                class="col-lg-12 col-md-12 required"
                                :name="`lbl_template_${index}`"
                                >Template</label
                              >
                              <CCol md="12">
                                <ValidationProvider
                                  rules="required"
                                  v-slot="{ errors }"
                                >
                                  <Select
                                    name="template_id"
                                    :value="temp.template_id"
                                    :options="
                                      options && options['templates']
                                        ? options['templates']
                                        : []
                                    "
                                    @change="
                                      (name, value) =>
                                        handleChangeSelect(name, value, index)
                                    "
                                    :multiple="false"
                                    :clearable="false"
                                    :error="errors[0]"
                                  />
                                </ValidationProvider>
                              </CCol>
                            </CRow>
                          </CCol>
                          <CCol
                            md="6"
                            v-for="(field, sub_index) in temp.fields"
                            :key="sub_index"
                          >
                            <CRow>
                              <label
                                class="col-lg-12 col-md-12 required"
                                :name="`lbl_${field}_${index}`"
                                >{{ field | startCase }}</label
                              >
                              <CCol
                                md="12"
                                v-if="
                                  temp.fileInputFields &&
                                  temp.fileInputFields.includes(field)
                                "
                              >
                                <input
                                  :ref="`file_${index}`"
                                  :name="field"
                                  type="file"
                                  accept="image/*"
                                  @change="
                                    (event) =>
                                      handleSelectedFile(field, event, index)
                                  "
                                />
                              </CCol>
                              <CCol md="12" v-else>
                                <ValidationProvider
                                  rules="required"
                                  v-slot="{ errors }"
                                >
                                  <TextAreaInput
                                    :name="field"
                                    :value="temp.data[field]"
                                    @input="
                                      (name, value) =>
                                        handleChangeInput(name, value, index)
                                    "
                                    :error="errors[0]"
                                    :rows="3"
                                  />
                                </ValidationProvider>
                              </CCol>
                            </CRow>
                          </CCol>
                        </CRow>
                      </div>
                    </div>
                  </CCol>
                  <div class="d-flex w-100">
                    <hr
                      :class="
                        templateData.length === index + 1 ? 'w-45' : 'w-95'
                      "
                    />
                    <div>
                      <a
                        class="btn px-0 mx-1 text-success"
                        title="Add"
                        @click="addOneTemplate"
                        v-if="templateData.length === index + 1"
                        ><i class="fa-solid fa-circle-plus fs-20"></i
                      ></a>
                    </div>
                    <hr
                      :class="{ 'w-45': templateData.length === index + 1 }"
                    />
                  </div>
                </CRow>
                <!-- </draggable> -->
              </form>
            </ValidationObserver>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol md="5">
        <CCard>
          <CCardBody class="preview-body">
            <h5 class="text-primary">Preview</h5>
            <div class="preview">
              <div v-for="(temp, index) in payload" :key="index">
                <component :is="temp.component" :data="temp.data" />
              </div>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CRow class="justify-content-center">
      <CButton
        class="mx-3 btn-submit"
        color="primary"
        variant="outline"
        type="button"
        @click="cancelCallBack"
        >Cancel</CButton
      >
      <CButton
        class="mx-3 btn-submit"
        color="primary"
        type="button"
        @click="onSubmit"
        >Submit</CButton
      >
    </CRow>
    <Modal
      v-if="deleteConfirmModal.isShowPopup"
      :modalTitle="deleteConfirmModal.modalTitle"
      :modalColor="deleteConfirmModal.modalColor"
      :modalContent="deleteConfirmModal.modalContent"
      :isShowPopup="deleteConfirmModal.isShowPopup"
      :buttons="deleteConfirmModal.buttons"
      :modalCallBack="deleteModalCallBack"
      :closeOnBackdrop="false"
    />
    <CropperModal
      v-if="cropperModal.isShowPopup"
      :isShowPopup="cropperModal.isShowPopup"
      :fileData="cropperModal.fileData"
      modalColor="primary"
      :buttonLabel="cropperModal.buttonLabel"
      @modalCallBack="cropperModalCallBack"
      :aspectRatio="cropperModal.aspectRatio"
    />
  </div>
</template>
<script>
import * as _ from "lodash";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import Select from "@/components/reusable/Fields/Select.vue";
import TextAreaInput from "@/components/reusable/Fields/TextareaInput";
import Modal from "@/components/reusable/Modal";
import CropperModal from "@/components/reusable/CropperModal";

import Template1 from "./Template-1";
import Template2 from "./Template-2";
import Template3 from "./Template-3";
import Template4 from "./Template-4";

// import draggable from "vuedraggable";
import CourseTemplateSchema from "@/schema/courseTemplateSchema";

Vue.component("Template1", Template1);
Vue.component("Template2", Template2);
Vue.component("Template3", Template3);
Vue.component("Template4", Template4);

export default {
  name: "AddTemplate",
  props: {
    course_id: {
      type: Number,
      default: null,
    },
    templateInfo: {
      type: Array,
      // default: [],
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Select,
    TextAreaInput,
    Modal,
    // draggable,
    CropperModal,
  },
  data() {
    return {
      templateData: [{ data: {} }],
      payload: [],
      deleteConfirmModal: {
        modalColor: "danger",
        modalTitle: "NOTIFICATION",
        modalContent: "",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
        data: null,
      },
      cropperModal: {
        isShowPopup: false,
        fileData: null,
        buttonLabel: "Apply",
        aspectRatio: 0,
        data: null,
      },
    };
  },
  computed: {
    options() {
      return {
        templates: this.templateOpt,
      };
    },
    templateOpt() {
      return CourseTemplateSchema.map((v) => ({
        label: v.title,
        code: v.template_id,
      }));
    },
  },
  methods: {
    ...mapActions(["showToast", "createCourseTemplate"]),
    isHasEmptyField() {
      return this.templateData.some((v) => {
        const fields = v.fields || [];
        const res = fields.length
          ? fields.some((field) => !v.data[field])
          : true;
        return v.template_id?.code && res;
      });
    },
    handleChangeSort(event) {
      const { oldIndex, newIndex } = event?.moved;
      this.payload = this.array_move(this.payload, oldIndex, newIndex);
    },
    array_move(arr, old_index, new_index) {
      if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      return arr;
    },
    openDeleteModal(index) {
      this.deleteConfirmModal.modalTitle = "NOTIFICATION";
      this.deleteConfirmModal.modalContent =
        "Are you sure you want to delete this Section?";
      this.deleteConfirmModal.isShowPopup = true;
      this.deleteConfirmModal.data = index;
    },
    deleteModalCallBack(action) {
      if (action === "Confirm") {
        const { data } = this.deleteConfirmModal;
        this.templateData.splice(data, 1);
        this.payload.splice(data, 1);
      }
      this.deleteConfirmModal.isShowPopup = false;
      this.deleteConfirmModal.data = null;
    },
    addOneTemplate() {
      this.templateData.push({ data: {} });
    },
    getEmbededURL(url) {
      const regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);

      return match && match[2].length === 11 ? match[2] : null;
    },
    handleChangeInput(name, value, index) {
      Vue.set(this.templateData[index].data, name, value);
      if (name.includes("video")) {
        const embedURL = this.getEmbededURL(value);
        Vue.set(this.payload[index].data, name, embedURL);
      } else {
        Vue.set(this.payload[index].data, name, value);
      }
      // this.$set(this.payload, index, data);
    },
    getTemplateSchemaData(template_id) {
      return CourseTemplateSchema.find((v) => v.template_id === template_id);
    },
    handleChangeSelect(name, value, index) {
      this.templateData[index] = { data: {} };
      Vue.set(this.templateData[index], name, value);
      const res = this.getTemplateSchemaData(value.code);
      if (res) {
        Vue.set(this.templateData[index], "fields", res.fields);
        Vue.set(
          this.templateData[index],
          "fileInputFields",
          res.fileInputFields
        );
        const fields = _.reduce(
          res.fields,
          (acc, field) => ({ ...acc, [field]: null }),
          {}
        );
        const data = {
          template_id: res?.template_id,
          component: res?.component,
          data: fields,
        };
        // this.payload.push(data);
        this.$set(this.payload, index, data);
      }
    },
    async onSubmit() {
      const isValid = await this.$refs.templateForm.validate();
      const hasEmptyField = this.isHasEmptyField();
      if (!isValid || hasEmptyField) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Please fill mandatory fields!",
        });
        return;
      }
      let finalPayload = this.payload.map((v, index) => {
        return {
          template_id: v?.template_id,
          template_order: index + 1,
          template_fields: {
            ...v.data,
          },
        };
      });
      // this.createCourseTemplate({
      //   training_course_id: this.course_id,
      //   payload: finalPayload,
      // }).then((res) => {
      //   if (res.status == 200) {
      //     this.$router.push(`/course/${this.course_id}`);
      //   }
      // });
      this.$emit("onSubmit", 
        finalPayload
      );
    },
    handleSelectedFile(name, event, index) {
      const ref = `file_${index}`;
      const size = event.target.files[0].size;
      if (Math.round(size / (1024 * 1024)) <= 2) {
        const file = event.target.files[0];
        if (file.type.startsWith("image/")) {
          this.cropperModal.fileData = file;
          this.cropperModal.isShowPopup = true;
          this.cropperModal.data = {
            index,
            name,
          };
        } else {
          this.$refs[ref].value = "";
          this.showToast({
            class: "bg-danger text-white",
            message: "Please select Image",
          });
          return;
        }
      } else {
        this.$refs[ref].value = "";
        this.showToast({
          class: "bg-danger text-white",
          message: "File too Big, please select a file less than 2mb",
        });
        return;
      }
    },
    cropperModalCallBack(action, data) {
      const { index, name } = this.cropperModal.data;
      if (action === false) {
        this.cropperModal.isShowPopup = false;
        this.cropperModal.fileData = null;
        this.cropperModal.aspectRatio = 0;
        const ref = `file_${index}`;
        this.$refs[ref].value = "";
      } else {
        let { fileBlob: file, fileName } = data;
        Vue.set(this.templateData[index].data, name, file);
        Vue.set(this.payload[index].data, name, file);
        this.cropperModal.isShowPopup = false;
        this.cropperModal.fileData = null;
        this.cropperModal.data = null;
        this.cropperModal.aspectRatio = 0;
      }
    },
    cancelCallBack() {
      this.$emit("cancelCallBack");
    },
    populateData(data) {
      const sortedData = data?.sort(
        (a, b) => a.template_order - b.template_order
        );
      sortedData.forEach((v, index) => {
        const fields = v.template_fields.map((v) => v.field_name);
        const fieldsObj = _.reduce(v.template_fields, (acc, field) => {
          const d = {
            ...acc,
            [field.field_name]: field.field_value,
          };
          if (this.isEdit) d.template_field_uid = field.template_field_uid;
          return d;
        }, {});
        const schemaData = this.getTemplateSchemaData(v.template_id);
        const d = {
          template_id: { code: v.template_id, label: schemaData?.title },
          fields,
          data: fieldsObj,
        };
        if (this.templateData[index]) {
          this.templateData[index] = d;
        } else {
          this.templateData.push(d);
        }
        const payload = {
          template_id: v?.template_id,
          component: schemaData?.component,
          data: fieldsObj,
        };
        if (this.isEdit) {
          payload.course_template_uid = v.course_template_uid;
        }
        this.payload.push(payload);
      });
    },
  },
  mounted() {
    if (this.templateInfo?.length) {
      this.populateData(this.templateInfo);
    }
  },
  filters: {
    startCase(data) {
      return data ? _.startCase(data) : data;
    },
  },
};
</script>
<style lang="scss" scoped>
hr {
  align-self: center;
  background: #eee;
  height: 1px;
}

.w-45 {
  width: 45%;
}

.w-95 {
  width: 95%;
}
.section {
  min-height: 300px;
}

#section-1 {
  background-color: transparent;
  background-image: linear-gradient(130deg, #00b9de 0%, #1c3788 89%);
}

#section-2 {
  background-color: #fff;
}
.preview,
.preview-body {
  background-color: #eeeeee;
  border: 1px solid #c0c0c0;
}

.handle {
  display: inline-block;
  width: 16px;
  height: 8px;
}

.handle,
.handle::before {
  background-image: radial-gradient(black 40%, transparent 40%);
  background-size: 4px 4px;
  background-position: 0 100%;
  background-repeat: repeat-x;
}

.handle::before {
  content: "";
  display: block;
  width: 100%;
  height: 33%;
}
</style>
